import { CreateWcClaimAttachmentInput } from "@amzn/ttechclaimintakeservice-client";
import { MetricsPublisher } from "src/metrics";
import { createWcClaimAttachmentUrlService } from "./createWcClaimAttachmentUrlService";

const RADIX_DECIMAL = 10;

export async function uploadWcClaimAttachment(
  input: CreateWcClaimAttachmentInput,
  file: File
) {
  const metricsPublisher = new MetricsPublisher(
    "api.createWcClaimAttachmentUrlService"
  );
  try {
    const url = await createWcClaimAttachmentUrlService(input);
    if (typeof url !== "string") {
      throw new Error("Invalid URL");
    }
    // Valid URL attempt to upload the file
    return await attemptFileUpload(url, file);
  } catch (error: any) {
    metricsPublisher.publishFileUploadURLRetryMetrics();
    throw error;
  }
}

async function attemptFileUpload(url: string, file: File) {
  let response = await uploadFiles(url, file);
  if (response.ok) {
    return response.ok;
  } else {
    // If file upload fails the first time make a second attempt.
    // Then return status with either success/failure.
    response = await uploadFiles(url, file);
    return response.status;
  }
}

async function uploadFiles(url: string, file: File) {
  const metricsPublisher = new MetricsPublisher("api.uploadWcClaimAttachment");
  const metricsPublisherError = new MetricsPublisher(
    "api.uploadWcClaimAttachment.error"
  );
  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-type": file.type,
    },
  });
  if (response.ok) {
    metricsPublisher.publishFileUploadSuccessMetrics();
    metricsPublisher.publishFileUploadLatencyMetrics();
    return response;
  } else {
    metricsPublisher.publishFileUploadFailureMetrics();
    metricsPublisher.publishFileUploadRetryMetrics();
    metricsPublisherError.publishFileUploadError(
      response.status.toString(RADIX_DECIMAL)
    );
    throw new Error("Error uploading file");
  }
}
