import { v4 } from "uuid";
import type {
  FormFieldStateType,
  FormFieldType,
  FormStateType,
} from "../types";
import type { S3StagedFile, SerializedFile } from "./types";
import { FileSize } from "./types";
import { addError } from "../tools";
import { ALL_FILE_UPLOAD_FIELDS } from "../constants";
import {
  ACCEPTED_FILE_TYPES,
  ALL_FILES_MAX_SIZE,
  MAX_FILENAME_SIZE,
  SINGLE_FILE_MAX_SIZE,
} from "./constants";
import { uploadWcClaimAttachment } from "src/api/uploadWcClaimAttachment";

/**
 * Converts bytes to the specified file size unit.
 *
 * @export
 * @param {number} bytes - The number of bytes to convert.
 * @param {FileSize} unit - The target file size unit to convert to.
 * @returns {number} The converted file size in the specified unit.
 *
 */
export const convertBytesTo = (bytes: number, unit: FileSize): number => {
  switch (unit) {
    // Decimal
    case FileSize.KB: {
      return bytes / 1000;
    }
    case FileSize.MB: {
      return bytes / 1000 ** 2;
    }
    case FileSize.GB: {
      return bytes / 1000 ** 3;
    }
    // Binary
    case FileSize.KIB: {
      return bytes / 1024;
    }
    case FileSize.MIB: {
      return bytes / 1024 ** 2;
    }
    case FileSize.GIB: {
      return bytes / 1024 ** 3;
    }
    // Default
    case FileSize.BYTES:
    default: {
      return bytes;
    }
  }
};

/**
 * Checks the total size of all files and add an error if it exceeds the maximum allowed size
 * @param {File[]} files - All the files to be checked
 * @param {string[]} errors - An array to store error messages that will be used by the file attachment field
 */
const checkTotalFilesSize = (files: File[], errors: string[]) => {
  let totalSize = 0;

  files.forEach((file: File) => {
    if (file != null && "size" in file) {
      totalSize = totalSize + file.size;
    }
  });
  if (totalSize >= ALL_FILES_MAX_SIZE) {
    addError("Total combined files size can't exceed 29MB", errors);
  }
};

/**
 * Creates a new file with a new name
 * @param {File} originalFile - The original file to be renamed
 * @param {string} newName - The new name for the file
 * @returns {File} - A new file with the new name
 */
const renameFile = (originalFile: File, newName: string) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

/**
 * Replaces all space separators in file names with normal spaces to avoid
 * issues with file names when uploading files.
 * @param {File[]} files - An array of files
 * @returns {File[]} - An array of files with modified names
 */
export const replaceSpaceSeparatorsWithNormalSpace = (files: File[]) => {
  const modifiedFiles: File[] = [];
  for (const file of files) {
    // eslint-disable-next-line no-useless-escape
    const fileName = file.name.replaceAll(/\s/g, " ");
    modifiedFiles.push(renameFile(file, fileName));
  }
  return modifiedFiles;
};

/**
 * Checks the file size and add an error if it exceeds the maximum allowed size
 * @param {File} file - The file to be checked
 * @param {string[]} errors - An array to store error messages that will be used by the file attachment field
 */
export const checkFileSize = (file: File, errors: string[]) => {
  if (file.size >= SINGLE_FILE_MAX_SIZE) {
    addError("File size can't exceed 6MB", errors);
  }
};

/**
 * Checks the file type agains the list of accepted file types defined in ACCEPTED_FILE_TYPES
 * @param {File} file - The file to be checked
 * @param {string[]} errors - An array to store error messages that will be used by the file attachment field
 */
export const checkFileTypes = (file: File, errors: string[]) => {
  const extension: string | undefined = file.name
    .toLowerCase()
    .split(".")
    .pop();
  if (!extension || !ACCEPTED_FILE_TYPES?.includes(extension)) {
    addError("Unsupported file type", errors);
  }
};

/**
 * Checks that the file name does not exceed the maximum amount of characters allowed defined
 * in MAX_FILENAME_SIZE
 * @param {File} file - The file to be checked
 * @param {string[]} errors - An array to store error messages that will be used by the file attachment field
 */
export const checkFileNameSize = (file: File, errors: string[]) => {
  if (file.name.length > MAX_FILENAME_SIZE) {
    addError(
      `File name cannot be more than ${MAX_FILENAME_SIZE} characters long`,
      errors
    );
  }
};

/**
 * Generates an S3 UUID key that will get used as the filename to store in S3 when uploading files
 * @param {File} file - The file to be uploaded
 * @param {string} amazonClaimReferenceId - The claim reference id used to generate the S3 key
 * @returns {string} - The S3 key for the file
 */
export const generateS3Key = (file: File, amazonClaimReferenceId: string) => {
  const extension: string | undefined = file.name.split(".").pop();
  return `${amazonClaimReferenceId}/${v4()}.${extension}`;
};

/**
 * Checks for duplicate files in the list of files to be uploaded
 * @param {File[]} files - The list of files to be uploaded
 * @param {File} fileToCheck - The file to check for duplicates
 * @param {string[]} errors - An array to store error messages that will be used by the file attachment field
 */
export const checkDuplicateFiles = (
  files: File[],
  fileToCheck: File,
  errors: string[]
) => {
  const duplicates = files.filter(
    (file: File) => file.name === fileToCheck.name
  );
  if (duplicates.length > 1) {
    addError(
      "You are attempting to upload what appears to be a duplicate file",
      errors
    );
  }
};

/**
 * Prepares the files to be stored in the field state. This is required because Redux
 * store cannot have non-serializable objects like File objects, so we need to convert
 * the File objects to SerializedFile objects before storing them in the field state.
 * SerializedFile is a simple object that contains the necessary information to display
 * the file in the UI
 * @param {S3StagedFile[]} files - The list of files to be uploaded
 * @param {FormFieldStateType} fieldState - The field state object
 * @param {string} amazonClaimReferenceId - The claim reference id used to generate the S3 key
 * @returns {SerializedFile[]} - The list of files to be stored in the field state
 */
export const prepareFilesForStorage = (
  files: S3StagedFile[],
  fieldState: FormFieldStateType,
  amazonClaimReferenceId: string
): SerializedFile[] => {
  return files.map((file: S3StagedFile) => {
    const fileFound = fieldState.value?.find(
      (serializedFile: SerializedFile) => {
        return file.name === serializedFile.name;
      }
    );
    if (fileFound) {
      return fileFound;
    }
    return {
      s3Key: file.s3Key || generateS3Key(file, amazonClaimReferenceId),
      name: file.name,
      lastModified: file.lastModified,
      type: file.type,
      size: file.size,
      webkitRelativePath: file.webkitRelativePath,
    };
  });
};

/**
 * Adds the S3 key to each file in the list of files
 * @param {File[]} files - The list of files to be uploaded
 * @param {string} amazonClaimReferenceId - The claim reference id used to generate the S3 key
 * @returns {S3StagedFile[]} - The list of files with the S3 key added
 */
export const convertFilesToS3StagedFiles = (
  files: File[],
  amazonClaimReferenceId: string
) => {
  const s3StagedFiles: S3StagedFile[] = [];
  files.forEach((file: File) => {
    const s3File: S3StagedFile = file;
    s3File.s3Key = generateS3Key(file, amazonClaimReferenceId);
    s3StagedFiles.push(s3File);
  });
  return s3StagedFiles;
};

/**
 * Selects the files that are not already in the field state to avoid
 * running validations and uploading them again.
 * @param {File[]} files - The list of files to be uploaded
 * @param {FormFieldStateType} fieldState - The field state object
 * @returns {File[]} - The list of files that are not already in the field state
 */
export const selectFilesForUpload = (
  files: File[],
  fieldState: FormFieldStateType
) => {
  return files.filter((file: File) => {
    const fileFound = fieldState.value?.find(
      (serializedFile: SerializedFile) => serializedFile.name === file.name
    );
    // If file is not found in fieldState, return it for upload
    return !fileFound;
  });
};

/**
 * This function uploads a file to S3 using the uploadWcClaimAttachment service
 * it requires that files have already been processed to add the s3key to them.
 * @param {S3StagedFile} file - The file to be uploaded
 * @returns {Promise<void>} - A promise that resolves when the file is uploaded
 */
export const uploadFile = async (file: S3StagedFile) => {
  return await uploadWcClaimAttachment(
    {
      key: file.s3Key,
      contentLength: file.size,
    },
    file
  );
};

/**
 * Converts the files stored in the field state to File objects so that they
 * can be used by the FileUpload component.
 * @param {SerializedFile[]} serializedFiles - The list of files to be converted
 * @returns {File[]} - The list of files converted from SerializedFile to File
 */
export const transformSerializedFilesToFiles = (
  serializedFiles: SerializedFile[]
) => {
  const result: File[] = [];
  if (!serializedFiles.length) {
    return result;
  }
  serializedFiles.forEach((file: SerializedFile) => {
    result.push(new File([new ArrayBuffer(file.size)], file.name, { ...file }));
  });
  return result;
};

/**
 * Runs all the validations for the files to be uploaded
 * @param {File[]} files - The list of files to be uploaded
 * @param {string[]} errors - An array to store error messages that will be used by the file attachment field
 */
export const validateUploadedFiles = (files: File[], errors: string[]) => {
  checkTotalFilesSize(files, errors);
  for (const file of files) {
    checkFileTypes(file, errors);
    checkFileSize(file, errors);
    checkFileNameSize(file, errors);
    checkDuplicateFiles(files, file, errors);
    if (errors.length) {
      return;
    }
  }
};

/**
 * Runs all the validations for the files to be uploaded
 * @param {File[]} files - The list of files to be uploaded
 * @param {string[]} errors - An array to store error messages that will be used by the file attachment field
 */
export const getFilesFromOtherFields = (
  field: FormFieldType,
  formState: FormStateType
) => {
  const files: SerializedFile[] = [];
  Object.entries(formState).forEach(([key, fieldState]) => {
    if (ALL_FILE_UPLOAD_FIELDS.includes(key) && key !== field.config.name) {
      files.push(...fieldState.value);
    }
  });
  return transformSerializedFilesToFiles(files);
};

/**
 * Validates and uploads the files to S3 and updates the field state
 * @param {File[]} inputFiles - The list of files to be uploaded
 * @param {FormFieldType} field - The field object
 * @param {string} amazonClaimReferenceId - The claim reference id used to generate the S3 key
 * @param {FormStateType} formState - The form state object
 * @returns {FormFieldStateType} - The updated field state object
 */
export const validateAndUploadFiles = async (
  inputFiles: File[],
  field: FormFieldType,
  amazonClaimReferenceId: string,
  formState: FormStateType
) => {
  const errors: string[] = [];
  field.state.errors = [];
  const files = replaceSpaceSeparatorsWithNormalSpace(inputFiles);
  if (files.length < field.state.value.length) {
    const filesForStorage = prepareFilesForStorage(
      files,
      field.state,
      amazonClaimReferenceId
    );
    field.state.value = [...filesForStorage];
    // No need to upload files that are already in storage. We're just updating the field.state.
    return field.state;
  }
  const filesFromOtherFields = getFilesFromOtherFields(field, formState);

  validateUploadedFiles(
    [...filesFromOtherFields, ...files],
    field.state.errors
  );
  if (field.state.errors.length) {
    return field.state;
  }
  const filesForUpload = selectFilesForUpload(files, field.state);
  const S3StagedFiles = convertFilesToS3StagedFiles(
    filesForUpload,
    amazonClaimReferenceId
  );

  for (const file of S3StagedFiles) {
    try {
      await uploadFile(file);
    } catch (error) {
      addError("Error uploading file. Please try again.", errors);
      field.state.errors = [...errors];
      return field.state;
    }
  }
  const filesForStorage = prepareFilesForStorage(
    S3StagedFiles,
    field.state,
    amazonClaimReferenceId
  );
  field.state.value = [...field.state.value, ...filesForStorage];
  return field.state;
};
